import React from "react"
import logo from './logoblanco.png';
 const Footer = () => {

    return (
     <>
     <div className="footer-content">
   
     <div className="redessociales">
     <img src= {logo}  alt="logo del método haz que suceda"/>
     <a href="https://www.instagram.com/titolgonzalezoficial/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram-square instagram-icon"></i>
      </a>

      <a href="https://www.youtube.com/@metodohazquesuceda" target="_blank" rel="noopener noreferrer">
      <i class="fa-brands fa-youtube youtube-icon"></i>
      </a>

      <a href="https://www.linkedin.com/in/titolucerogonzalez" target="_blank" rel="noopener noreferrer">
      <i class="fa-brands fa-linkedin linkedin-icon"></i>
      </a>
     </div>
     
    
     <div className="copyright">
     <h1>©[2023]Premium Academy-Método Haz que Suceda. Todos los derechos reservados.</h1>
     </div>
     </div>
    </>
    );
};

export default Footer;
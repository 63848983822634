import React from "react"
import logoblanco from './logoblanco.png';
import certificado from './certificado.jpg';
import firmatitorojo from './firmatitorojo.png';
import logocursohoriz from './logocursohoriz.jpeg';
import comprasegura from './CompraSegura.png';
import garantia from './garantia.png';
import hotmart from  './hotmart.png';
import logopa from './logoPA.png';


 const Body = () => {
    const scrollToPasarela = () => {
        const pasarelaElement = document.querySelector('.pasarela');
        if (pasarelaElement) {
          pasarelaElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
     <>
     <div className="contenedor">
     <div className="metodo"> 
     
    <img src= {logoblanco} alt="logo del método haz que suceda"/>

     </div>

<div className="content-info">
    <div className="parrafo">
     
     <p>
     Hay patrones o circunstancias en la vida del ser humano que se repiten.<span> Sana tus Heridas</span> es una estrategia de la metodología <span> Haz que Suceda</span> que te permite encontrar una razón válida de porque hago lo que hago. Con el fin de Romper esos patrones repetitivos en tu vida.
     </p>
  
    </div>

    </div>

  
    </div>


    <div className="content-publicidad">
     
     <label>¿Quieres Romper aquellas circunstancias repetitivas en tu Vida? </label>
     
    </div>

    <div class="bottonpasarela" >
    <button onClick={scrollToPasarela} className="custom-button">Matricúlate ahora</button>
    </div>
   

    <div className="content-descripcion">
    
    <div className="video">
      
    <iframe
        src="https://www.youtube.com/embed/BjOTDnbtmto?si=9DL4L7YpfP2ZSUi9"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>

    </div>

    <div className="texto"> 
   <p>
   Descubre aquellos  patrones que te afectan los diferentes ámbitos de fracasos y rompelos a través de herramientas que te ayudaran a obtener resultados que siempre has deseado y que ya otros tienen. Enseñar a las personas como encontrar su patrón para romper aquellos fracasos a través de diferentes herramientas es nuestro principal propósito. Logrando realizar cambios positivos en su vida sea personal, profesional o corporativa.
   </p>

    </div>

    </div>
 
    <div className="content-publicidadd">
     
     <label> 
        ¡ Crece, Sana y Logra tus postenciales con nuestro Curso ! </label>
    </div>

<div className="contenidocurso">
   
        <label>1. Cambio de Mentalidad</label>
      
     
        <label>2. Aplica lo que Aprendes</label>
    
     
        <label> 3. Preparate para el Éxito</label>

   
        <label> 4. Crea tu nueva Realiadad</label>
  
     </div>

    <div className="contenido">
   
        <label> 5. Modifica tus Archivos</label>
    
     
        <label> 6. Niveles Lógicos</label>
  
    
        <label> 7. Sana Tu Herida</label>
    

    </div>

    
    <div className="curso">
        <div className="descripcion">
           <p>
          <span>🔥¡Descubre el poder transformador de Sana Tus Heridas! </span>
          Con esta estrategia única de la metodología <span>“Haz que Suceda”</span>, aprenderás a identificar y romper los patrones que han estado afectando todos los ámbitos de tus fracasos.
          <ul>
           <li> Desbloquea tu potencial para lograr los resultados que siempre has deseado, siguiendo herramientas probadas que ya han transformado vidas. Rompe con los patrones limitantes que obstaculizan tu éxito en lo personal, profesional y corporativo.
           </li>
           <li>Obtén un certificado que valida tu compromiso con el cambio positivo. ¡Tu viaje hacia una vida más plena y exitosa!
           </li>
           </ul>
            </p>
            <div className="precio">
        
       
       <h1> $ 14,99</h1>
       </div> 
      
        </div>
       
      
        <div className="certificado">
        
    <img src= {certificado}  alt="logo del método haz que suceda"/>
        </div>
    </div>

    <div className="matriculapubli">
     
     <label> Deligencia los datos, Matriculate al curso y comienza a  ¡Sanar Tus Heridas!</label>
    </div>
   <div className="labelcurso">

    <label> Para Transfomar tu Vida y convertir tus Sueños en realiadad. </label>
    </div>
<div className="pasarela">

 <div className="checkout">
 <iframe src="https://pay.hotmart.com/T88642367W?checkoutMode=2">
 </iframe>

 
 </div>
 
<div className="imagenes">
 <div className="portadacurso">
<img src= {logocursohoriz}  alt="logo del método haz que suceda"/>
 </div>

 <div className="logoo">
 <img src= {logoblanco} alt="logo del método haz que suceda"/>
 </div>

 <div className="garantia">
<img src={comprasegura} alt="metodo haz que suceda"/>
 </div>

<div className="sellog">
    <img src= {garantia} alt="metodo haz que suceda"/>

</div>



<div className="logos">
<img src = {hotmart} alt="metodo haz que suceda"/>

<img src = {logopa} alt="metodo haz que suceda"/>

</div>

<div className="firma">
    <img src= {firmatitorojo}  alt="logo del método haz que suceda"/>
   
    </div>
<div className="mhs">
<p>Método Haz que Suceda © [2023]</p>

</div>
 </div>

</div>


    </>
    );
};

export default Body;

import React from 'react';
import logo from './logo.svg';
import './App.css';

import Header from './componentes/Header';
import Footer from './componentes/Footer';
import Body from './componentes/Body';
import '@fortawesome/fontawesome-free/css/all.css';

function App() {
  return (
    <div className="App">
     
     <header className="Header">

      <Header />
     </header>

      <body className="App-body">
        <Body />
      </body>

      <footer className="Footer">
      <Footer />
      </footer>
    </div>
  );
}

export default App;

import React from "react"

 const Header = () => {

    return (
     <>
    
    <div className="container">
    <div className="sanatusheridas">
    <h1> Sana Tus Heridas</h1>
    </div>
    </div>


    </>
    );
};

export default Header;